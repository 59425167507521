@import url('https://cdn.rawgit.com/moonspam/NanumSquare/master/nanumsquare.css');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

#__next {
  min-height: 100%;
}

.title {
    @apply text-2xl font-bold leading-6 text-gray-900
}

/* 상단 메뉴 아이템 */
.nav-menu {
   @apply transition-all cursor-pointer text-gray-800 hover:text-orange-400  text-[18px] font-montserrat hover:font-bold;
}

/* 리뷰 답글 버튼 */
.review-comment-button {
    @apply bg-gray-100 hover:bg-orange-400 hover:text-white flex  items-center justify-center rounded px-2 py-1;
}

.input {
    @apply block w-full rounded-md border border-gray-300 py-3 px-4 text-gray-900 focus:border-orange-300 focus:ring-orange-300 focus:outline-none disabled:bg-zinc-100 disabled:text-zinc-500 placeholder:text-zinc-300
}

.button {
    @apply cursor-pointer transition-all hover:text-white inline-flex items-center justify-center rounded-md border border-transparent bg-orange-400 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-orange-400 focus:ring-offset-2 sm:w-auto disabled:bg-gray-300
}

.button-outline {
    @apply  cursor-pointer transition-all hover:text-white inline-flex items-center justify-center rounded-md border border-orange-400 hover:border-orange-500 bg-transparent px-4 py-2 text-sm font-medium text-orange-400 shadow-sm hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-orange-400 focus:ring-offset-2 sm:w-auto
}

.button-gray {
    @apply cursor-pointer transition-all hover:text-white inline-flex items-center justify-center rounded-md border border-transparent bg-gray-400 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 sm:w-auto
}

.button-red {
    @apply transition-all hover:text-white inline-flex items-center justify-center rounded-md border border-transparent bg-red-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto
}

.button-green {
    @apply transition-all hover:text-white inline-flex items-center justify-center rounded-md border border-transparent bg-green-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:w-auto
}

.button-blue {
    @apply transition-all hover:text-white inline-flex items-center justify-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto
}

.button-white{
    @apply rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-zinc-800 shadow-sm hover:bg-zinc-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-400 cursor-pointer
}

.select {
    @apply border border-gray-200 text-gray-900 text-sm rounded focus:outline-none focus:ring-green-500 focus:border-green-500 px-4 py-3
}

.label {
    @apply block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400
}

.error {
    @apply text-xs  text-red-500
}

.menu-text-link {
    @apply text-white hover:text-white transition-colors font-light hover:text-orange-400
}

/* .button-red {
    @apply px-8 py-3 rounded bg-primary hover:bg-[#FD0836] text-white hover:text-white text-lg font-semibold cursor-pointer
} */

.button-red-outline {
    @apply px-8 py-3 rounded border border-primary hover:border-[#FD0836] bg-dark hover:bg-rose-600 text-primary hover:text-white text-lg font-semibold
}


.card {
    @apply px-10 py-5 border rounded-lg flex flex-col items-center justify-center
}

/* reqct quill editor size */
.ql-editor{
    min-height:200px;
}

.swiper {
  @apply  pb-10 !important;
}


.swiper-pagination-bullet{
    @apply !bg-gray-600

} 
.swiper-pagination-bullet-active {
    @apply !bg-orange-400
}

.max-width {
    @apply max-w-7xl mx-auto
}

.card-container{
    @apply shadow-lg p-6 bg-white
}

/* ant design image center on click */
img.ant-image-preview-img { display: inline-block; } 